/* CSS Custom Properties */
:root {
  /* Color */
  --blue-gradient-fallback: #2a3ef3;
  --blue-gradient: linear-gradient(173deg, #0967fe 14%, #2a3ef3 84%);
  --blue-1: #0047FF;
  --blue-2: #0029FF;
  --blue-3: #0026EB;
  --blue-4: #0400DB;
  --blue-5: #0400BB;
  --red-1: #FF5757;
  --red-2: #EB4545;
  --red-3: #D34141;
  --red-4: #B53232;
  --red-5: #B02020;
  --red-6: #A51111;
  --orange-1: #FFA800;
  --orange-2: #FF9900;
  --orange-3: #FF8A00;
  --orange-4: #FF7A00;
  --orange-5: #FF5C00;
  --orange-6: #FF4D00;
  --green-1: #20BF5F;
  --green-2: #12AB4F;
  --green-3: #00983C;
  --green-4: #00983C;
  --green-5: #006E33;
  --green-6: #005829;
  --midnight-1: #000935;
  --midnight-2: #04104B;
  --midnight-3: #000F5F;
  --midnight-4: #001377;
  --midnight-5: #030091;
  --midnight-6: #0300AE;
  --dark-1: #161619;
  --dark-2: #232327;
  --dark-3: #3D3D4B;
  --dark-4: #5A5A6E;
  --dark-5: #92929F;
  --dark-6: #ACACB3;
  --dark-7: #E6E7EF;
  --light-1: #FFFFFF;
  --light-2: #FCFCFD;
  --light-3: #F6F6F6;
  --light-4: #EFEFF0;
  --light-5: #EAEAEA;
  --light-6: #E0E0E0;
  --white: #ffffff;
  --purple-1: #EEEEFF;


  /* Spacing */
  /* For margin, padding, etc */
  --spacing-0: 0;
  --spacing-1: 0.25rem; /* 4px */
  --spacing-2: 0.5rem; /* 8px */
  --spacing-3: 1rem; /* 16px */
  --spacing-4: 1.5rem; /* 24px */
  --spacing-5: 2rem; /* 32px */
  --spacing-6: 3rem; /* 48px */
  --spacing-7: 5rem; /* 80px */

  /* font-family */
  --font-serif: 'Montserrat', 'Arial', sans-serif;
  --font-sans: 'Roboto', 'Arial', serif;

  /* font-size */
  --font-2xl: 1.75rem; /* 28px */
  --font-xl: 1.375rem; /* 22px */
  --font-lg: 1.125rem; /* 18px */
  --font-base: 1rem; /* 16px */
  --font-sm: 0.875rem; /* 14px */
  --font-xs: 0.625rem; /* 10px */

  /* font-weight */
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;

  /* line-height */
  --line-xl: 2.25rem; /* 36px */
  --line-lg: 2rem; /* 32px */
  --line-base: 1.5rem; /* 24px */
  --line-sm: 1.375rem; /* 22px */
  --line-xs: 1.25rem; /* 20px */
  --line-2xs: 1rem; /* 16px */

  /* border-radius */
  --rounded: 3px;
  --rounded-lg: 6px;
  --rounded-full: 40px;

  /* Shadows */
  --card-shadow:
    0px 2px 32px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.08);
  --movement-shadow:
    0px 2px 48px rgba(0, 0, 0, 0.08),
    0px 4px 16px rgba(0, 0, 0, 0.1);
  --modal-shadow:
    0px 2px 60px rgba(0, 0, 0, 0.16),
    0px 4px 18px rgba(0, 0, 0, 0.16);
  --card-flat: 1px solid var(--light-5);
}
