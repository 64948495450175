// color overrides
$blue: #2a3ef3;
$old-blue: #167df0;
$pink: #ff5757;
$yellow: #f6a200;
$deep-blue: #18065e;
$dark-blue: #0c003b;
$black: #0f0428;
$background-dark: #241f46;
$green: #42d17b;
$white: #ffffff;
$background-light: #fcfcff;
$background-grey: #fbfbfb;
$background-grey-light: #f6f6f6;
$lighter-grey: #f4f4f4;
$light-grey: #f0f0f3;
$greyer: #dadada;
$darker-grey-ada-compliant: #3d3d65;
$grey: #afadbe;
$grey-ada-compliant: #807f84;
$dark-grey-slightly: #6d6d76;
$dark-grey: #7c7c86;
$dark-grey-ada-compliant: #383737;
$dark-contrast: #524e6e;
$radius: 3px;
$big-radius: 6px;
// Priority
$primary: $deep-blue;
$info: $blue;
// $primary: $blue;
// $info: $deep-blue;
$warning: $yellow;
$dark: $black;
$light: $light-grey;
$success: $green;

// fonts
$family-serif: 'Montserrat', 'Arial', sans-serif;
$family-sans-serif: 'Roboto', 'Arial', serif;

// style guide - fonts
$break-max-desktop-plus: 1400px;
$break-max-desktop: 1216px;
$break-max-tablet: 1100px;
$break-max-phone: 768px;
$title-breakpoint: 768px;
$app-nav-width: 70px;

// Modal
$modal-background-background-color: rgba($black, 0.6);

// Link
$link: $blue;

// Button
$button-color: $white;
$button-background-color: $blue;
$button-hover-color: $white;
$button-focus-color: $white;
$button-active-color: $white;
$button-disabled-opacity: 1;
$button-disabled-background-color: $light-grey;

// Style Guide Spacing
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px

@import './montserrat.scss';
@import './roboto.scss';
@import './variables.scss';
// Use the new serif family
$family-primary: $family-sans-serif;
$body-background: $background-light;
$input-focus-border-color: $white;
$table-background-color: $white;
$warning-invert: $white;

// This is needed for oruga's bulma theme!
@import "~bulma/sass/utilities/_all";
@import '~bulma/bulma';
@import '~@oruga-ui/theme-bulma/dist/scss/bulma';
@import '~@oruga-ui/oruga-next/dist/oruga.css';
@import '~@vueup/vue-quill/dist/vue-quill.core.css';
@import '~@vueup/vue-quill/dist/vue-quill.snow.css';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/css/all.css';

@import '~material-design-icons/iconfont/material-icons.css';

@import '~@mdi/font/css/materialdesignicons.min.css';
@import '~swiper/swiper-bundle.css';
@import '~vue3-typer/dist/vue-typer.css';

body {
  // font smoothing
  // https://www.leejamesrobinson.com/blog/how-stripe-designs-beautiful-websites/
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hero {
  background-color: $white;
}

.padded-content {
  padding-bottom: 5rem;
  padding-top: 5rem;
  width: 100%;

  @media screen and (max-width: $break-max-tablet) {
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 2rem;
    padding-top: 2rem;
    width: 90%;
  }
}

.is-focusable:focus {
  border: none;
  box-shadow: none !important;
}
.wide-image-header {
  width: 100%;
}

.content {
  h1,
  .h1 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.2rem;
    color: $black;
    font-family: $family-serif;
    font-weight: 600;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
  h2,
  .h2 {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    color: $black;
    font-family: $family-serif;
    font-weight: 600;
    &:not(:first-child) {
      margin-bottom: 0;
    }
  }
  h3,
  .h3 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    color: $background-dark;
    font-family: $family-serif;
    font-weight: 600;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
  h4,
  .h4 .blog-header {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6rem;
    color: $background-dark;
    font-family: $family-serif;
    font-weight: 500;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
  p,
  .p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $black;
    font-family: $family-sans-serif;
  }
  a {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    font-family: $family-sans-serif;
  }
  caption,
  .caption {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    color: $dark-grey-ada-compliant;
    font-family: $family-sans-serif;
  }
}

.padded-button {
  font-size: 1rem;
  padding: 1.3rem;
  font-weight: 600 !important;
  line-height: 0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: $break-max-phone) {
    width: 100%;
  }
}

.select select {
  background-color: inherit;
}

.secondary-button {
  color: $blue;
  background-color: white;
  border: 2px solid $blue;
  &:hover {
    color: $blue;
    border: 2px solid $blue;
    background-color: $background-grey;
  }
}

.label {
  font-weight: 400;
}

.input {
  background: none;
  border-radius: 3px;
  box-sizing: border-box;
  &.input-light {
    color: #ffffff;
  }
}

.quill-editor {
  background-color: $white;
  .ql-toolbar.ql-snow {
    border: 1px solid $greyer;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .ql-container.ql-snow {
    border: 1px solid $greyer;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

// bullets on text editor: user profiles, job posts
.ql-editor {
  p{
    color: $dark-blue;
  }
  ol, ul {
    padding-left: 0em;
    font-size: 1rem;
    color: $dark-blue;
  }
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0px;
}

// style guide - colors
.is-black {
  color: $black;
}

// v-select plugin
.v-select .selected-tag {
  border: none !important;
  background-color: inherit !important;
}

.v-select .dropdown-menu > .highlight > a {
  /*
     * required to override bootstrap 3's
     * .dropdown-menu > li > a:hover {} styles
     */
  background: $pink !important;
  color: $white !important;
}

// Make v-select full width by default
.v-select {
  width: 100%;
  .dropdown-toggle {
    width: 100%;
  }
}

.dropdown-item.is-active {
  background-color: white !important;
  color: black !important;
  min-width: 100%;

  &:hover {
    cursor: pointer;
    background-color: #F9F9F9 !important;
  }
}

.dropdown-item {
  padding: 0.375rem 0;
  min-width: 100%;
  &:hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }
}

.autocomplete .dropdown-menu .dropdown-item {
  padding: 0.5rem;
  min-width: 100%;
  &:hover {
    cursor: pointer;
    background-color: #F9F9F9;
  }
}

.dropdown-item .dropdown-item-content {
  color: black;
  min-width: 100%;
  display: block;
  font-weight: 400;
  padding: 0 1rem;
}

.app-container {
  padding: 2rem;
  @media screen and (max-width: $break-max-phone) {
    padding: 20px 20px;
  }
}

// warning notification text should be white
.notification.is-warning {
  color: $white;
  p {
    color: $white;
    font-weight: 500;
  }
}

.notices.is-toast {
  opacity: 1; // defaults to .92
}

.notices .toast.is-dark {
  // default is gradient
  background-image: linear-gradient(168deg, #0967fe, #2a3ef3);
}

.notification.is-info {
  background-image: linear-gradient(168deg, #0967fe, #2a3ef3);
  border: none;
  color: white;
  font-weight: 500;
}

.preserve-newlines,
.preserve-newslines {
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word;
}

.muted {
  opacity: 0.5;
}

.card {
  border-radius: 6px;
  box-shadow: none;
  border: $light-grey 1px solid;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}

.card-content {
  border-radius: 6px;
  padding: 2rem;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.modal-padding {
  padding: 2rem;
  @media screen and (max-width: $break-max-phone) {
    padding: 1rem;
  }
}

.circle {
  border: $blue 2px solid;
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  padding-top: 0.4rem;
  line-height: 1rem;
  -moz-border-radius: 30px; /* or 50% */
  border-radius: 30px; /* or 50% */
  margin-right: 1rem;
}

.is-borderless {
  td {
    border: none !important;
  }
  th {
    border: none !important;
  }
}

// make text in pre wrap
// https://css-tricks.com/snippets/css/make-pre-text-wrap/
pre.ql-syntax {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.clickable {
  cursor: pointer;
}

// flatpicker fixes
.flatpickr-current-month {
  padding-top: 1px !important;
}
.flatpickr-day.selected {
  background: $primary !important;

  border-color: $primary !important;
}

.button {
  border: 2px solid $blue;
}

.button.is-primary.is-inverted.is-outlined {
  background: none;
  color: white;
}

.link-button {
  border: none;
  color: $blue;
  font-family: Roboto;
  font-size: 1rem;
}

.field {
  .message {
    background: none;
    .message-header {
      background: none;
      color: $grey;
      padding-left: 0;
    }

    &.is-warning {
      .message-header {
        color: $warning;
      }
    }

    &.is-info {
      .message-header {
        color: $info;
      }
    }

    &.is-primary {
      .message-header {
        color: $primary;
      }
    }
  }
}

.textarea::placeholder,
.input::placeholder {
  color: $grey;
}

.button {
  @each $name, $pair in $colors {
    &[disabled],
    fieldset[disabled] {
      background-color: $grey-light;
    }

    &.is-#{$name} {
      &[disabled],
      fieldset[disabled] {
        background-color: $grey-light;
      }
    }
  }
}

.container {
  @media screen and (max-width: $break-max-tablet) {
    margin-left: 5%;
    margin-right: 5%;
  }
}

// Grey bands we use on referrals and user settings
.settings-section {
  width: 100%;
  margin: 24px 0;
  background-color: $background-grey;
}

.button.is-text {
  text-decoration: none;
}

.quill-editor {
  .ql-container {
    min-height: 10rem;
  }
}

#plaid-link-iframe-1 {
  height: 100%;
}
